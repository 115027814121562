import React from "react"
import StyledHeading from "./style/heading";

const Heading = ({ text, color }) => {
  return (
    <StyledHeading>
      <h2 className={`${color}`}>
        {text}
      </h2>
    </StyledHeading >
  )
};

export default Heading
