import React from "react"
import { graphql } from "gatsby"
import Banner from "../components/careers-list/Banner"
import Layout from "../components/layout"
import Details from "../components/careers-list/Details"
import SEO from "../components/seo"
import Header from "../components/common/Header"
import MetaTags from "react-meta-tags"

const TagsTemplate = ({ pageContext, data }) => {
  return (
    <Layout>
      <SEO
        title={` Cresol | ${pageContext.tag}`}
        description="Explore exciting career opportunities at Cresol, a dynamic leader in the ITindustry. Join our team of innovative professionals and contribute to groundbreaking solutions that transform industries. Discover diverse roles across research and development, engineering, sales and marketing, and more. "
      />

      {/* <Banner tag={pageContext.tag} /> */}
      <Header
        title="Careers"
        description="Pilot your career in the right direction, Build your profession that suits your lifestyle, in a company where your voice matters. Let's build a livable world together"
      />
      <Details data={data} />
    </Layout>
  )
}

export default TagsTemplate

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(filter: { frontmatter: { categories: { in: [$tag] } } }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          heading
          subHeading
          details {
            designation
            numberOfPositions
            positionType
            experience
            jobType
            location
          }
        }
      }
    }
  }
`
