import tw, { styled } from "twin.macro"

const StyledDetails = styled.section`
  ${tw`md:py-8 py-6`};
  .main-container {
    ${tw`flex flex-col 2xl:px-[160px] sm:px-[50px] px-[10px] mx-auto z-[1] relative gap-x-16 items-center justify-center gap-4`}
    p {
      ${tw`font-medium`}
    }
    .table-wrapper {
      ${tw`w-full`}
      > div {
        ${tw`overflow-x-auto sm:mx-0.5 lg:mx-0.5`}

        > div {
          ${tw`py-2 inline-block min-w-full sm:px-6 lg:px-8`}

          > div {
            ${tw`overflow-hidden`}

            table {
              ${tw`min-w-full`}

              thead {
                ${tw`border-b bg-primary`}

                tr {
                  th {
                    ${tw`text-white font-medium px-6 py-4 text-left`}
                  }
                }
              }

              tbody {
                tr {
                  ${tw`border-b`}

                  td {
                    ${tw`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-black`}

                    &.in-icon {
                      a {
                        ${tw`flex flex-row gap-2 items-center text-secondary`}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default StyledDetails
