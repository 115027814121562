import tw, { styled } from 'twin.macro';

const StyledHeading = styled.div`
${tw`flex justify-center items-center m-auto`}
h2 {
  ${tw`relative md:(text-4xl mb-8) mb-4 text-2xl font-bold text-center`}
  &:after {
    ${tw`[content: ''] absolute h-1 md:-bottom-4 -bottom-2 bg-secondary mx-auto left-0 right-0 w-24 rounded-xl`}
  }
  &.white {
    ${tw`text-white`}
  }
  &.primary {
    ${tw`text-primary`}
  }
}
`;

export default StyledHeading;