import React from "react"
import StyledDetails from "./style/details"
import Heading from "../common/Heading"
import { FaEye } from "react-icons/fa"
import { Link } from "gatsby"
import Header from "../common/Header"

const toKebabCase = str => {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("-")
}

const Details = ({ data }) => {
  const tableData = data.allMarkdownRemark.nodes

  return (
    <StyledDetails>
      <div className="main-container">
        <h2>{data.allMarkdownRemark.nodes[0].frontmatter.heading}</h2>
        <p>{data.allMarkdownRemark.nodes[0].frontmatter.subHeading}</p>
        {tableData && (
          <div className="table-wrapper">
            <div>
              <div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Number of Positions</th>
                        <th scope="col">Position Type</th>
                        <th scope="col">Experience</th>
                        <th scope="col">WFH/WFO</th>
                        <th scope="col">Location</th>
                        <th scope="col">View</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {tableData &&
                        tableData.map((d, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {d.frontmatter.details &&
                                d.frontmatter.details.designation}
                            </td>
                            <td>
                              {d.frontmatter.details &&
                                d.frontmatter.details.numberOfPositions}
                            </td>
                            <td>
                              {d.frontmatter.details &&
                                d.frontmatter.details.positionType}
                            </td>
                            <td>
                              {d.frontmatter.details &&
                                d.frontmatter.details.experience}
                            </td>
                            <td>
                              {d.frontmatter.details &&
                                d.frontmatter.details.jobType}
                            </td>
                            <td>
                              {d.frontmatter.details &&
                                d.frontmatter.details.location}
                            </td>
                            <td className="in-icon">
                              <Link to={d.fields && d.fields.slug}>
                                <FaEye />
                                View Job
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </StyledDetails>
  )
}

export default Details
