import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import MetaTags from "react-meta-tags"

const SEO = ({ title, description, image, article, keywords }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  const metaKeywords = keywords || []

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Corporation",
        name: "cresol infoserve",
        url: "https://cresolinfoserv.com/",
        logo: "",
        sameAs: [
          "https://www.facebook.com/people/Cresol-Infoserv-Pvt-Ltd/100094261784613/?mibextid=ZbWKwL",
          "https://www.instagram.com/cresol_infoserv/?igshid=MzRlODBiNWFlZA%3D%3D",
        ],
      })}
    </script>
  )

  return (
    <MetaTags titleTemplate={titleTemplate}>
      {structuredData}

      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={metaKeywords} />
      <meta http-equiv="cleartype" content="on" />
      <meta name="author" content="Cresol Infoserv" />
      <meta name={seo.url} CONTENT={seo.title} />
      <meta name="rating" CONTENT="General" />
      <meta name="robots" content="index,follow,noarchive" />
      <meta name="revisit-after" CONTENT="daily" />
      <meta content="English" name="language" />
      <link
        href="https://cresolinfoserv.com/opensearch.xml"
        rel="search"
        title={seo.title}
        type="application/opensearchdescription+xml"
      />
      <link rel="canonical" href={seo.url} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.url && <meta property="og:url" content={seo.url} />}

      <meta property="og:site_name" content={seo.title} />
    </MetaTags>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
